import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _44b8ae10 = () => interopDefault(import('../pages/aanmelden.vue' /* webpackChunkName: "pages/aanmelden" */))
const _17987eb3 = () => interopDefault(import('../pages/direct/index.vue' /* webpackChunkName: "pages/direct/index" */))
const _648f355a = () => interopDefault(import('../pages/genres/index.vue' /* webpackChunkName: "pages/genres/index" */))
const _4036ca7a = () => interopDefault(import('../pages/gratis-sexfilms/index.vue' /* webpackChunkName: "pages/gratis-sexfilms/index" */))
const _8d7c24d4 = () => interopDefault(import('../pages/modellen/index.vue' /* webpackChunkName: "pages/modellen/index" */))
const _5c348d11 = () => interopDefault(import('../pages/nieuws/index.vue' /* webpackChunkName: "pages/nieuws/index" */))
const _09ac4998 = () => interopDefault(import('../pages/overzicht/index.vue' /* webpackChunkName: "pages/overzicht/index" */))
const _567da7e4 = () => interopDefault(import('../pages/privefilmpjes/index.vue' /* webpackChunkName: "pages/privefilmpjes/index" */))
const _1c4187c1 = () => interopDefault(import('../pages/series/index.vue' /* webpackChunkName: "pages/series/index" */))
const _b092cc5a = () => interopDefault(import('../pages/sexfilms/index.vue' /* webpackChunkName: "pages/sexfilms/index" */))
const _7318cc66 = () => interopDefault(import('../pages/transactie.vue' /* webpackChunkName: "pages/transactie" */))
const _0e3e7468 = () => interopDefault(import('../pages/tv-gids/index.vue' /* webpackChunkName: "pages/tv-gids/index" */))
const _257dc297 = () => interopDefault(import('../pages/webcamsex/index.vue' /* webpackChunkName: "pages/webcamsex/index" */))
const _4fa2dec4 = () => interopDefault(import('../pages/zoekresultaten/index.vue' /* webpackChunkName: "pages/zoekresultaten/index" */))
const _1a185755 = () => interopDefault(import('../pages/account/direct/index.vue' /* webpackChunkName: "pages/account/direct/index" */))
const _dd87f2ba = () => interopDefault(import('../pages/account/gegevens-voorkeuren/index.vue' /* webpackChunkName: "pages/account/gegevens-voorkeuren/index" */))
const _ee5a0cfa = () => interopDefault(import('../pages/account/gehuurd.vue' /* webpackChunkName: "pages/account/gehuurd" */))
const _3579c41d = () => interopDefault(import('../pages/account/inloggen/index.vue' /* webpackChunkName: "pages/account/inloggen/index" */))
const _eb2c5954 = () => interopDefault(import('../pages/account/mijn-credits.vue' /* webpackChunkName: "pages/account/mijn-credits" */))
const _294c9912 = () => interopDefault(import('../pages/account/mijn-favorieten.vue' /* webpackChunkName: "pages/account/mijn-favorieten" */))
const _d428b908 = () => interopDefault(import('../pages/account/registreren/index.vue' /* webpackChunkName: "pages/account/registreren/index" */))
const _af647bb0 = () => interopDefault(import('../pages/account/wachtwoord-herstellen.vue' /* webpackChunkName: "pages/account/wachtwoord-herstellen" */))
const _76b4d14c = () => interopDefault(import('../pages/account/wachtwoord-vergeten.vue' /* webpackChunkName: "pages/account/wachtwoord-vergeten" */))
const _95a801e6 = () => interopDefault(import('../pages/acties/adventskalender/index.vue' /* webpackChunkName: "pages/acties/adventskalender/index" */))
const _1f5c4bc0 = () => interopDefault(import('../pages/acties/black-friday-2024/index.vue' /* webpackChunkName: "pages/acties/black-friday-2024/index" */))
const _b47b2002 = () => interopDefault(import('../pages/acties/chicas-de-holanda/index.vue' /* webpackChunkName: "pages/acties/chicas-de-holanda/index" */))
const _7b4a2a99 = () => interopDefault(import('../pages/acties/droomvrouw-lexa/index.vue' /* webpackChunkName: "pages/acties/droomvrouw-lexa/index" */))
const _a0ec5cd2 = () => interopDefault(import('../pages/acties/energie-opwekkers/index.vue' /* webpackChunkName: "pages/acties/energie-opwekkers/index" */))
const _743c9b63 = () => interopDefault(import('../pages/acties/hollandszaad/index.vue' /* webpackChunkName: "pages/acties/hollandszaad/index" */))
const _51fccbee = () => interopDefault(import('../pages/acties/inmeikiesjij/index.vue' /* webpackChunkName: "pages/acties/inmeikiesjij/index" */))
const _543daa68 = () => interopDefault(import('../pages/acties/kamasutra-2024/index.vue' /* webpackChunkName: "pages/acties/kamasutra-2024/index" */))
const _091b3f72 = () => interopDefault(import('../pages/acties/live-meekijken.vue' /* webpackChunkName: "pages/acties/live-meekijken" */))
const _33d1558a = () => interopDefault(import('../pages/acties/live-meekijken/index.vue' /* webpackChunkName: "pages/acties/live-meekijken/index" */))
const _21670db8 = () => interopDefault(import('../pages/acties/live-meekijken/aanmeldformulier/index.vue' /* webpackChunkName: "pages/acties/live-meekijken/aanmeldformulier/index" */))
const _725d153d = () => interopDefault(import('../pages/acties/live-meekijken/aanmeldformulier/bedankt.vue' /* webpackChunkName: "pages/acties/live-meekijken/aanmeldformulier/bedankt" */))
const _594c04d9 = () => interopDefault(import('../pages/acties/parkeerplaatssex/index.vue' /* webpackChunkName: "pages/acties/parkeerplaatssex/index" */))
const _7b4a4114 = () => interopDefault(import('../pages/acties/sexcamper/index.vue' /* webpackChunkName: "pages/acties/sexcamper/index" */))
const _fde5d700 = () => interopDefault(import('../pages/acties/sexmetkerst.vue' /* webpackChunkName: "pages/acties/sexmetkerst" */))
const _46d83b03 = () => interopDefault(import('../pages/acties/sexmetkerst/index.vue' /* webpackChunkName: "pages/acties/sexmetkerst/index" */))
const _235aa95f = () => interopDefault(import('../pages/acties/sexmetkerst/aanmeldformulier/index.vue' /* webpackChunkName: "pages/acties/sexmetkerst/aanmeldformulier/index" */))
const _744f39b8 = () => interopDefault(import('../pages/acties/sexmetkerst/aanmeldformulier/bedankt.vue' /* webpackChunkName: "pages/acties/sexmetkerst/aanmeldformulier/bedankt" */))
const _509746fa = () => interopDefault(import('../pages/acties/valentijn/index.vue' /* webpackChunkName: "pages/acties/valentijn/index" */))
const _6a293788 = () => interopDefault(import('../pages/acties/webcamsex.vue' /* webpackChunkName: "pages/acties/webcamsex" */))
const _2b4de308 = () => interopDefault(import('../pages/acties/xl-deals-2024.vue' /* webpackChunkName: "pages/acties/xl-deals-2024" */))
const _5169af5a = () => interopDefault(import('../pages/betalen/direct.vue' /* webpackChunkName: "pages/betalen/direct" */))
const _d6a7465a = () => interopDefault(import('../pages/betalen/huurtegoed.vue' /* webpackChunkName: "pages/betalen/huurtegoed" */))
const _a4c2732a = () => interopDefault(import('../pages/info/algemene-voorwaarden.vue' /* webpackChunkName: "pages/info/algemene-voorwaarden" */))
const _6dbf97c9 = () => interopDefault(import('../pages/info/contact/index.vue' /* webpackChunkName: "pages/info/contact/index" */))
const _66f364dc = () => interopDefault(import('../pages/info/feedback/index.vue' /* webpackChunkName: "pages/info/feedback/index" */))
const _1dd7edfc = () => interopDefault(import('../pages/info/nieuwsbrief/index.vue' /* webpackChunkName: "pages/info/nieuwsbrief/index" */))
const _07e0391e = () => interopDefault(import('../pages/info/over.vue' /* webpackChunkName: "pages/info/over" */))
const _b801eda4 = () => interopDefault(import('../pages/info/privacy.vue' /* webpackChunkName: "pages/info/privacy" */))
const _ccdceec6 = () => interopDefault(import('../pages/info/sex-link-partners.vue' /* webpackChunkName: "pages/info/sex-link-partners" */))
const _12150d14 = () => interopDefault(import('../pages/info/tv-zender-ontvangen.vue' /* webpackChunkName: "pages/info/tv-zender-ontvangen" */))
const _3442f1b1 = () => interopDefault(import('../pages/info/uitschrijven/index.vue' /* webpackChunkName: "pages/info/uitschrijven/index" */))
const _39254dc1 = () => interopDefault(import('../pages/info/veelgestelde-vragen.vue' /* webpackChunkName: "pages/info/veelgestelde-vragen" */))
const _5e9e38bc = () => interopDefault(import('../pages/account/direct/opzeggen.vue' /* webpackChunkName: "pages/account/direct/opzeggen" */))
const _76da074d = () => interopDefault(import('../pages/account/gegevens-voorkeuren/verwijder-account.vue' /* webpackChunkName: "pages/account/gegevens-voorkeuren/verwijder-account" */))
const _de96d6c2 = () => interopDefault(import('../pages/account/inloggen/bevestigen.vue' /* webpackChunkName: "pages/account/inloggen/bevestigen" */))
const _df6b19a8 = () => interopDefault(import('../pages/account/registreren/pincode.vue' /* webpackChunkName: "pages/account/registreren/pincode" */))
const _4d6901c4 = () => interopDefault(import('../pages/acties/chicas-de-holanda/bedankt.vue' /* webpackChunkName: "pages/acties/chicas-de-holanda/bedankt" */))
const _47a79c48 = () => interopDefault(import('../pages/acties/hollandszaad/actie-voorwaarden.vue' /* webpackChunkName: "pages/acties/hollandszaad/actie-voorwaarden" */))
const _09219b0c = () => interopDefault(import('../pages/acties/hollandszaad/betaald.vue' /* webpackChunkName: "pages/acties/hollandszaad/betaald" */))
const _a2a63ddc = () => interopDefault(import('../pages/acties/hollandszaad/cadeau.vue' /* webpackChunkName: "pages/acties/hollandszaad/cadeau" */))
const _84faeef8 = () => interopDefault(import('../pages/acties/hollandszaad/groeikaart.vue' /* webpackChunkName: "pages/acties/hollandszaad/groeikaart" */))
const _203e3827 = () => interopDefault(import('../pages/acties/hollandszaad/verstuurd.vue' /* webpackChunkName: "pages/acties/hollandszaad/verstuurd" */))
const _4065f298 = () => interopDefault(import('../pages/acties/inmeikiesjij/gekozen.vue' /* webpackChunkName: "pages/acties/inmeikiesjij/gekozen" */))
const _7c75f36e = () => interopDefault(import('../pages/acties/parkeerplaatssex/aanmeldformulier/index.vue' /* webpackChunkName: "pages/acties/parkeerplaatssex/aanmeldformulier/index" */))
const _cbb53124 = () => interopDefault(import('../pages/acties/sexcamper/aanmeldformulier/index.vue' /* webpackChunkName: "pages/acties/sexcamper/aanmeldformulier/index" */))
const _05d49042 = () => interopDefault(import('../pages/acties/valentijn/aanmeldformulier/index.vue' /* webpackChunkName: "pages/acties/valentijn/aanmeldformulier/index" */))
const _0abb5f0e = () => interopDefault(import('../pages/info/contact/bedankt.vue' /* webpackChunkName: "pages/info/contact/bedankt" */))
const _5aa872d2 = () => interopDefault(import('../pages/info/feedback/bedankt.vue' /* webpackChunkName: "pages/info/feedback/bedankt" */))
const _1616f481 = () => interopDefault(import('../pages/info/nieuwsbrief/bedankt.vue' /* webpackChunkName: "pages/info/nieuwsbrief/bedankt" */))
const _3a8c8f2d = () => interopDefault(import('../pages/info/nieuwsbrief/uitschrijven/index.vue' /* webpackChunkName: "pages/info/nieuwsbrief/uitschrijven/index" */))
const _0603f891 = () => interopDefault(import('../pages/info/uitschrijven/uitgeschreven.vue' /* webpackChunkName: "pages/info/uitschrijven/uitgeschreven" */))
const _731f2d8e = () => interopDefault(import('../pages/acties/parkeerplaatssex/aanmeldformulier/bedankt.vue' /* webpackChunkName: "pages/acties/parkeerplaatssex/aanmeldformulier/bedankt" */))
const _96284d1a = () => interopDefault(import('../pages/acties/sexcamper/aanmeldformulier/bedankt.vue' /* webpackChunkName: "pages/acties/sexcamper/aanmeldformulier/bedankt" */))
const _1d09d1a4 = () => interopDefault(import('../pages/acties/valentijn/aanmeldformulier/bedankt.vue' /* webpackChunkName: "pages/acties/valentijn/aanmeldformulier/bedankt" */))
const _7c865a0d = () => interopDefault(import('../pages/info/nieuwsbrief/uitschrijven/uitgeschreven.vue' /* webpackChunkName: "pages/info/nieuwsbrief/uitschrijven/uitgeschreven" */))
const _18f7f65b = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _62d89412 = () => interopDefault(import('../pages/genres/_slug.vue' /* webpackChunkName: "pages/genres/_slug" */))
const _3e802932 = () => interopDefault(import('../pages/gratis-sexfilms/_slug.vue' /* webpackChunkName: "pages/gratis-sexfilms/_slug" */))
const _90e96764 = () => interopDefault(import('../pages/modellen/_slug.vue' /* webpackChunkName: "pages/modellen/_slug" */))
const _5a7debc9 = () => interopDefault(import('../pages/nieuws/_slug.vue' /* webpackChunkName: "pages/nieuws/_slug" */))
const _07f5a850 = () => interopDefault(import('../pages/overzicht/_slug.vue' /* webpackChunkName: "pages/overzicht/_slug" */))
const _59eaea74 = () => interopDefault(import('../pages/privefilmpjes/_slug.vue' /* webpackChunkName: "pages/privefilmpjes/_slug" */))
const _1a8ae679 = () => interopDefault(import('../pages/series/_slug.vue' /* webpackChunkName: "pages/series/_slug" */))
const _b4000eea = () => interopDefault(import('../pages/sexfilms/_slug.vue' /* webpackChunkName: "pages/sexfilms/_slug" */))
const _07033092 = () => interopDefault(import('../pages/webcamsex/_slug/index.vue' /* webpackChunkName: "pages/webcamsex/_slug/index" */))
const _24aa9a5c = () => interopDefault(import('../pages/webcamsex/_slug/live.vue' /* webpackChunkName: "pages/webcamsex/_slug/live" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/aanmelden",
    component: _44b8ae10,
    name: "aanmelden"
  }, {
    path: "/direct",
    component: _17987eb3,
    name: "direct"
  }, {
    path: "/genres",
    component: _648f355a,
    name: "genres"
  }, {
    path: "/gratis-sexfilms",
    component: _4036ca7a,
    name: "gratis-sexfilms"
  }, {
    path: "/modellen",
    component: _8d7c24d4,
    name: "modellen"
  }, {
    path: "/nieuws",
    component: _5c348d11,
    name: "nieuws"
  }, {
    path: "/overzicht",
    component: _09ac4998,
    name: "overzicht"
  }, {
    path: "/privefilmpjes",
    component: _567da7e4,
    name: "privefilmpjes"
  }, {
    path: "/series",
    component: _1c4187c1,
    name: "series"
  }, {
    path: "/sexfilms",
    component: _b092cc5a,
    name: "sexfilms"
  }, {
    path: "/transactie",
    component: _7318cc66,
    name: "transactie"
  }, {
    path: "/tv-gids",
    component: _0e3e7468,
    name: "tv-gids"
  }, {
    path: "/webcamsex",
    component: _257dc297,
    name: "webcamsex"
  }, {
    path: "/zoekresultaten",
    component: _4fa2dec4,
    name: "zoekresultaten"
  }, {
    path: "/account/direct",
    component: _1a185755,
    name: "Direct"
  }, {
    path: "/account/gegevens-voorkeuren",
    component: _dd87f2ba,
    name: "Gegevens & voorkeuren"
  }, {
    path: "/account/gehuurd",
    component: _ee5a0cfa,
    name: "Gehuurd"
  }, {
    path: "/account/inloggen",
    component: _3579c41d,
    name: "account-inloggen"
  }, {
    path: "/account/mijn-credits",
    component: _eb2c5954,
    name: "Mijn credits"
  }, {
    path: "/account/mijn-favorieten",
    component: _294c9912,
    name: "Mijn favorieten"
  }, {
    path: "/account/registreren",
    component: _d428b908,
    name: "account-registreren"
  }, {
    path: "/account/wachtwoord-herstellen",
    component: _af647bb0,
    name: "account-wachtwoord-herstellen"
  }, {
    path: "/account/wachtwoord-vergeten",
    component: _76b4d14c,
    name: "account-wachtwoord-vergeten"
  }, {
    path: "/acties/adventskalender",
    component: _95a801e6,
    name: "acties-adventskalender"
  }, {
    path: "/acties/black-friday-2024",
    component: _1f5c4bc0,
    name: "acties-black-friday-2024"
  }, {
    path: "/acties/chicas-de-holanda",
    component: _b47b2002,
    name: "acties-chicas-de-holanda"
  }, {
    path: "/acties/droomvrouw-lexa",
    component: _7b4a2a99,
    name: "acties-droomvrouw-lexa"
  }, {
    path: "/acties/energie-opwekkers",
    component: _a0ec5cd2,
    name: "acties-energie-opwekkers"
  }, {
    path: "/acties/hollandszaad",
    component: _743c9b63,
    name: "acties-hollandszaad"
  }, {
    path: "/acties/inmeikiesjij",
    component: _51fccbee,
    name: "acties-inmeikiesjij"
  }, {
    path: "/acties/kamasutra-2024",
    component: _543daa68,
    name: "acties-kamasutra-2024"
  }, {
    path: "/acties/live-meekijken",
    component: _091b3f72,
    children: [{
      path: "",
      component: _33d1558a,
      name: "acties-live-meekijken"
    }, {
      path: "aanmeldformulier",
      component: _21670db8,
      name: "acties-live-meekijken-aanmeldformulier"
    }, {
      path: "aanmeldformulier/bedankt",
      component: _725d153d,
      name: "acties-live-meekijken-aanmeldformulier-bedankt"
    }]
  }, {
    path: "/acties/parkeerplaatssex",
    component: _594c04d9,
    name: "acties-parkeerplaatssex"
  }, {
    path: "/acties/sexcamper",
    component: _7b4a4114,
    name: "acties-sexcamper"
  }, {
    path: "/acties/sexmetkerst",
    component: _fde5d700,
    children: [{
      path: "",
      component: _46d83b03,
      name: "acties-sexmetkerst"
    }, {
      path: "aanmeldformulier",
      component: _235aa95f,
      name: "acties-sexmetkerst-aanmeldformulier"
    }, {
      path: "aanmeldformulier/bedankt",
      component: _744f39b8,
      name: "acties-sexmetkerst-aanmeldformulier-bedankt"
    }]
  }, {
    path: "/acties/valentijn",
    component: _509746fa,
    name: "acties-valentijn"
  }, {
    path: "/acties/webcamsex",
    component: _6a293788,
    name: "acties-webcamsex"
  }, {
    path: "/acties/xl-deals-2024",
    component: _2b4de308,
    name: "acties-xl-deals-2024"
  }, {
    path: "/betalen/direct",
    component: _5169af5a,
    name: "betalen-direct"
  }, {
    path: "/betalen/huurtegoed",
    component: _d6a7465a,
    name: "betalen-huurtegoed"
  }, {
    path: "/info/algemene-voorwaarden",
    component: _a4c2732a,
    name: "info-algemene-voorwaarden"
  }, {
    path: "/info/contact",
    component: _6dbf97c9,
    name: "info-contact"
  }, {
    path: "/info/feedback",
    component: _66f364dc,
    name: "info-feedback"
  }, {
    path: "/info/nieuwsbrief",
    component: _1dd7edfc,
    name: "info-nieuwsbrief"
  }, {
    path: "/info/over",
    component: _07e0391e,
    name: "info-over"
  }, {
    path: "/info/privacy",
    component: _b801eda4,
    name: "info-privacy"
  }, {
    path: "/info/sex-link-partners",
    component: _ccdceec6,
    name: "info-sex-link-partners"
  }, {
    path: "/info/tv-zender-ontvangen",
    component: _12150d14,
    name: "info-tv-zender-ontvangen"
  }, {
    path: "/info/uitschrijven",
    component: _3442f1b1,
    name: "info-uitschrijven"
  }, {
    path: "/info/veelgestelde-vragen",
    component: _39254dc1,
    name: "info-veelgestelde-vragen"
  }, {
    path: "/account/direct/opzeggen",
    component: _5e9e38bc,
    name: "account-direct-opzeggen"
  }, {
    path: "/account/gegevens-voorkeuren/verwijder-account",
    component: _76da074d,
    name: "account-gegevens-voorkeuren-verwijder-account"
  }, {
    path: "/account/inloggen/bevestigen",
    component: _de96d6c2,
    name: "account-inloggen-bevestigen"
  }, {
    path: "/account/registreren/pincode",
    component: _df6b19a8,
    name: "account-registreren-pincode"
  }, {
    path: "/acties/chicas-de-holanda/bedankt",
    component: _4d6901c4,
    name: "acties-chicas-de-holanda-bedankt"
  }, {
    path: "/acties/hollandszaad/actie-voorwaarden",
    component: _47a79c48,
    name: "acties-hollandszaad-actie-voorwaarden"
  }, {
    path: "/acties/hollandszaad/betaald",
    component: _09219b0c,
    name: "acties-hollandszaad-betaald"
  }, {
    path: "/acties/hollandszaad/cadeau",
    component: _a2a63ddc,
    name: "acties-hollandszaad-cadeau"
  }, {
    path: "/acties/hollandszaad/groeikaart",
    component: _84faeef8,
    name: "acties-hollandszaad-groeikaart"
  }, {
    path: "/acties/hollandszaad/verstuurd",
    component: _203e3827,
    name: "acties-hollandszaad-verstuurd"
  }, {
    path: "/acties/inmeikiesjij/gekozen",
    component: _4065f298,
    name: "acties-inmeikiesjij-gekozen"
  }, {
    path: "/acties/parkeerplaatssex/aanmeldformulier",
    component: _7c75f36e,
    name: "acties-parkeerplaatssex-aanmeldformulier"
  }, {
    path: "/acties/sexcamper/aanmeldformulier",
    component: _cbb53124,
    name: "acties-sexcamper-aanmeldformulier"
  }, {
    path: "/acties/valentijn/aanmeldformulier",
    component: _05d49042,
    name: "acties-valentijn-aanmeldformulier"
  }, {
    path: "/info/contact/bedankt",
    component: _0abb5f0e,
    name: "info-contact-bedankt"
  }, {
    path: "/info/feedback/bedankt",
    component: _5aa872d2,
    name: "info-feedback-bedankt"
  }, {
    path: "/info/nieuwsbrief/bedankt",
    component: _1616f481,
    name: "info-nieuwsbrief-bedankt"
  }, {
    path: "/info/nieuwsbrief/uitschrijven",
    component: _3a8c8f2d,
    name: "info-nieuwsbrief-uitschrijven"
  }, {
    path: "/info/uitschrijven/uitgeschreven",
    component: _0603f891,
    name: "info-uitschrijven-uitgeschreven"
  }, {
    path: "/acties/parkeerplaatssex/aanmeldformulier/bedankt",
    component: _731f2d8e,
    name: "acties-parkeerplaatssex-aanmeldformulier-bedankt"
  }, {
    path: "/acties/sexcamper/aanmeldformulier/bedankt",
    component: _96284d1a,
    name: "acties-sexcamper-aanmeldformulier-bedankt"
  }, {
    path: "/acties/valentijn/aanmeldformulier/bedankt",
    component: _1d09d1a4,
    name: "acties-valentijn-aanmeldformulier-bedankt"
  }, {
    path: "/info/nieuwsbrief/uitschrijven/uitgeschreven",
    component: _7c865a0d,
    name: "info-nieuwsbrief-uitschrijven-uitgeschreven"
  }, {
    path: "/",
    component: _18f7f65b,
    name: "index"
  }, {
    path: "/genres/:slug",
    component: _62d89412,
    name: "genres-slug"
  }, {
    path: "/gratis-sexfilms/:slug?",
    component: _3e802932,
    name: "gratis-sexfilms-slug"
  }, {
    path: "/modellen/:slug",
    component: _90e96764,
    name: "modellen-slug"
  }, {
    path: "/nieuws/:slug",
    component: _5a7debc9,
    name: "nieuws-slug"
  }, {
    path: "/overzicht/:slug",
    component: _07f5a850,
    name: "overzicht-slug"
  }, {
    path: "/privefilmpjes/:slug",
    component: _59eaea74,
    name: "privefilmpjes-slug"
  }, {
    path: "/series/:slug",
    component: _1a8ae679,
    name: "series-slug"
  }, {
    path: "/sexfilms/:slug",
    component: _b4000eea,
    name: "sexfilms-slug"
  }, {
    path: "/webcamsex/:slug",
    component: _07033092,
    name: "webcamsex-slug"
  }, {
    path: "/webcamsex/:slug/live",
    component: _24aa9a5c,
    name: "webcamsex-slug-live"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
